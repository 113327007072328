* {
    background-color: #f3f7fa;
    box-sizing: border-box;
    font: 15px "Helvetica", sans-serif;
    color: #717171;
}
.wrapper {
    text-align: center;
}
.plane_logo {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60px;
}
.container-fluid {
    display: flex;
    justify-content: center;
}
