.container_filters * {
    background-color: #fff;
}
.container_filters {
    text-align: start;
    width: 280px;
    height: max-content;
    padding: 20px;
    margin-right: 20px;
    background-color: #fff;
    border: 3px solid #f0f4f7;
    border-radius: 10px;
}
.btn-group {
    width: 100%;
    margin-top: 20px;
    align-items: center;
}
.btn_currency {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    height: 45px;
    align-items: center;
    border-color: #0192d8;
    color: #0192d8;
    font-size: 13px;
    font-weight: 600;
}
.btn-check:checked + .btn,
.btn.active {
    background-color: #0192d8;
    border-color: #0192d8;
    color: #b4e5fd;
}
.btn.btn-outline-primary:hover {
    background-color: #e6f6fa;
    color: #0192d8;
    border-color: #b4e5fd;
}
.btn:not(.btn-check) {
    border: 2px solid #f0f4f7;
}
.container_stops_filters {
    margin-top: 30px;
}
.title {
    margin: 0;
    font-weight: 600;
}
.checkbox_group {
    margin-top: 10px;
}
.container_checkbox {
    display: flex;
    align-items: center;
    margin-left: -20px;
    width: calc(100% + 40px);
    padding: 10px 20px 10px 10px;
}
.container_checkbox:hover {
    background-color: #f2fcff;
    cursor: pointer;
}
.container_checkbox:hover .onHover {
    background-color: #f2fcff;
    cursor: pointer;
}
.label-check {
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}
.custom_checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom_checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.custom_checkbox + label::before {
    content: "";
    display: inline-block;
    text-align: left;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.custom_checkbox:checked + label::before {
    border-color: #0192d8;
    background-color: #f2fcff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230192d8' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
